import type { FC } from 'react';
import { memo } from 'react';

import { Typography } from '@sravni/react-design-system';

import { ListKeys } from '@src/constants/microcredits';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import { useHasUtmLabel } from '@src/reducers/route';
import { LightweightCard } from 'widgets/LightweightCard';

import style from './SeoBestProducts.module.scss';

const { Heading } = Typography;

interface IProps {
  title?: string;
}

export const SeoBestProducts: FC<IProps> = memo(({ title }) => {
  const hasUtmLabel = useHasUtmLabel();

  const bestProductList = useSelectCreditsList(ListKeys.BESTPRODUCTS);
  const items = bestProductList?.items || [];

  if (!items.length) {
    return null;
  }

  return (
    <div>
      <Heading className={style.title} level={2}>
        {title || 'Популярные предложения'}
      </Heading>
      <div className={style.gridCards}>
        {items.map((item, index) => (
          <LightweightCard key={item._id} offer={item} position={index} hasUtmLabel={hasUtmLabel} />
        ))}
      </div>
    </div>
  );
});
